import { observable } from "mobx";
import moment from "moment";

import { getBookingCost } from ".";
import { PackageType, Spot, VehicleData } from "..";
import { calculateDuration } from "../../../helpers";
// import { WhitelistModel } from "../whitelist";

type Data = {
  userId: string;
  spot: Spot;
  startAt: Date;
  endAt: Date;
  vehicles: VehicleData[];
  accessPackage?: PackageType;
};

// export type BookingPlanWhitelist = {
//   whitelist: WhitelistModel;
//   available: boolean;
// };

export class BookingPlan {
  readonly data: Data;
  // private whitelists = observable([]);

  constructor(data: Data) {
    this.data = observable(data);
    this.verifyWhitelists();
  }

  async verifyWhitelists() {
    // const { userId, spot, vehicle } = this.data;
    // const whitelists = spot.whitelistsForUser(
    //   userId,
    //   vehicle ? vehicle.licensePlate : undefined
    // ).docs;
    // reaction(
    //   () => whitelists.map((whitelist) => whitelist.id).join(","),
    //   () => {
    //     this._whitelists.set(
    //       whitelists.map((whitelist) => ({ whitelist, available: true }))
    //     );
    //     const { startAt, endAt } = this;
    //     Promise.all(
    //       whitelists
    //         .filter((whitelist) => !whitelist.hasEnded)
    //         .map(async (whitelist) => {
    //           const available = await whitelist.available(startAt, endAt);
    //           return { whitelist, available };
    //         })
    //     ).then((whitelists) => {
    //       this._whitelists.set(whitelists);
    //     });
    //   }
    // );
  }

  get spot() {
    return this.data.spot;
  }

  get startAt() {
    return this.data.startAt;
  }

  get displayStartDate() {
    return moment(this.startAt).format("LL");
  }
  get displayStartTime() {
    return moment(this.startAt).format("LT");
  }

  get displayEndDate() {
    return moment(this.endAt).format("LL");
  }
  get displayEndTime() {
    return moment(this.endAt).format("LT");
  }
  // set startAt(startAt: Date) {
  //   this._data.set({ ...this.data, startAt });
  // }

  get endAt() {
    return this.data.endAt;
    // const startAt = this.startAt;
    // let endAt =
    //   this.data.endAt || new Date(this.startAt.getTime() + 24 * 60 * 60 * 1000);
    // if (!this.data.endAt) {
    //   const whitelists = this.availableWhitelists.map(({ whitelist }) => ({
    //     id: whitelist.id,
    //     ...whitelist.data,
    //   }));

    //   const whitelistCoverage = BookingModel.whitelistCoverage(
    //     startAt,
    //     endAt,
    //     whitelists,
    //     this.data.spot.timezone
    //   );
    //   if (whitelistCoverage.length) {
    //     if (
    //       whitelistCoverage[0].startAt.getTime() === startAt.getTime() &&
    //       whitelistCoverage[0].endAt.getTime() !== endAt.getTime()
    //     ) {
    //       endAt = whitelistCoverage[0].endAt;
    //     }
    //   }

    //   const availability = this.data.spot.getAvailabilityStatus({
    //     startAt,
    //     endAt,
    //   });
    //   if (!availability.open) {
    //     endAt = availability.endAt;
    //   }
    // }

    // return endAt;
  }

  // set endAt(endAt: Date) {
  //   this._data.set({ ...this.data, endAt });
  // }

  get vehicles() {
    return this.data.vehicles;
  }

  // set vehicle(vehicle: VehicleModel) {
  //   this._data.set({ ...this.data, vehicle });
  // }

  // TODO
  // get availableWhitelists(): BookingPlanWhitelist[] {
  //   return this.whitelists.filter(
  //     (whitelist) => whitelist.whitelist.hasStarted && whitelist.available
  //   );
  // }

  get isDirect() {
    return !this.data.startAt && !this.data.endAt;
  }

  get isLimited() {
    return (
      !this.data.endAt &&
      Math.round((this.endAt.getTime() - this.startAt.getTime()) / 1000) <
        24 * 60 * 60
    );
  }

  get isAutoEnd() {
    return (
      !this.data.endAt &&
      Math.round((this.endAt.getTime() - this.startAt.getTime()) / 1000) ===
        24 * 60 * 60
    );
  }

  get isOverdue() {
    return false; // !!this.data.overdue;
  }

  get cost() {
    const { startAt, endAt, isOverdue } = this;
    const { spot } = this.data;

    if (startAt && endAt) {
      const rates = spot.rates;

      let cost = getBookingCost(
        rates,
        startAt,
        endAt,
        spot.gracePeriod,
        isOverdue
      );
      if (this.data.accessPackage) {
        let accessPackage = this.spot.singleAccessPackageByType(
          this.data.accessPackage
        );
        cost = {
          amount: accessPackage?.rate.value ?? cost?.amount ?? 0,
          currency: accessPackage?.rate.currency ?? "EUR",
        };
      }
      if (this.vehicles.length > 1) {
        return {
          ...cost,
          amount: (cost?.amount || 0) * this.vehicles.length,
        };
      }
      return cost;
    } else return undefined;
  }

  get duration() {
    const { startAt, endAt } = this;
    if (!startAt || !endAt) return 0;
    return calculateDuration(startAt, endAt);
  }

  // TODO
  // get whitelistCoverage() {
  //   const { startAt, endAt } = this;
  //   const { spot } = this.data;
  //   const whitelists = this.availableWhitelists.map(({ whitelist }) => ({
  //     id: whitelist.id,
  //     ...whitelist.data,
  //   }));
  //   return BookingModel.whitelistCoverageTotal(
  //     startAt,
  //     endAt,
  //     whitelists,
  //     spot.timezone
  //   );
  // }

  // get startAction() {
  //   return new ActionModel({
  //     action: this.createAndStartBooking,
  //     label: t("Enter parking"),
  //     icon: "FA5:sign-in-alt",
  //     color: "purple",
  //   });
  // }

  // createAndStartBooking = asyncAction<{ store: any }, void>(async (config) => {
  //   const { store } = config;
  //   const { spot } = this.data;
  //   const { startAt, endAt, isDirect, vehicle } = this;
  //   const vehicleIds = [vehicle.id];
  //   const response = await store.bookings.create({
  //     spot,
  //     startAt: isDirect ? undefined : startAt,
  //     endAt,
  //     vehicleIds,
  //     cost: { amount: 0, currency: spot.rates.currency },
  //   });
  //   const booking = new BookingGuestModel(`bookings/${response.id}`);
  //   await booking.fetch();
  //   Routes.navigate("bookingDetails", { booking, spot });
  //   if (booking.canOpenEntrance) {
  //     await booking.openGate("entrance")({ store });
  //   }
  // });
}
