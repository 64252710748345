import { observer } from "mobx-react-lite";
import React from "react";
import { View, StyleSheet, ViewProps } from "react-native";
import { useMediaQuery } from "react-responsive";

import Language, { Props as LanguageProps } from "./language";
import { Colors, Margins } from "../../../storybook/constant";
import { Identity } from "../branding";

// @ts-ignore
export interface Props extends Omit<LanguageProps, "onChange">, ViewProps {
  onChangeLanguage?: (language: string) => void;
}

const Header = observer((props: Props) => {
  const {
    style,
    language,
    availableLanguages,
    onChangeLanguage,
    hitSlop,
    ...otherProps
  } = props;

  const isLargeDesktop = useMediaQuery({ minWidth: 1200 });

  return (
    <View
      style={[
        styles.header,
        { paddingHorizontal: isLargeDesktop ? 0 : Margins.regular },
        style,
      ]}
      {...otherProps}
    >
      <View style={styles.identity}>
        <Identity size="tiny" light />
      </View>
      <Language
        language={language}
        availableLanguages={availableLanguages}
        style={styles.language}
        onChange={onChangeLanguage}
      />
    </View>
  );
});

export default Header;

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: 80,
    // paddingHorizontal: Margins.regular,
    maxWidth: 900,
    width: "100%",
    marginHorizontal: "auto",
  },
  identity: {
    flex: 1,
    alignItems: "flex-start",
  },
  language: {
    alignItems: "flex-end",
  },
  divider: {
    height: 1,
    backgroundColor: Colors.lightGrey,
    marginVertical: Margins.tiny,
  },
  avatar: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: "lightgrey",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 2,
  },
  menu: {
    borderRadius: 8,
    padding: 8,
    paddingBottom: 0,
    // minWidth: 100,
  },
});
