import React, { ReactNode, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, TouchableOpacity } from "react-native";

import InputView, { Props as InputProps } from "./input";
import { PopUp, PopUpProps } from "../modal";
import { Text } from "../typography";

const styles = StyleSheet.create({
  container: {},
  content: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

interface Option {
  id: string;
  render: (selected?: boolean) => ReactNode;
}

export interface Props extends Omit<InputProps, "renderContent"> {
  value?: string;
  defaultValue?: string;
  options: Option[];
  picker?: PopUpProps;
  footer?: ReactNode;
  onChange?: (value: string) => void;
}

const Select = (props: Props) => {
  const { t } = useTranslation();
  const [showPicker, setShowPicker] = useState(false);
  const {
    label,
    borderColor,
    state,
    addon,
    style,
    contentStyle,
    disabled,
    value,
    defaultValue,
    options,
    picker,
    footer,
    onChange,
    ...otherProps
  } = props;

  const { visible, ...otherPicker } = picker || {};

  useEffect(() => {
    setShowPicker(!!visible);
  }, [visible]);

  const option =
    value || defaultValue
      ? options.find((option) => option.id === (value || defaultValue))
      : undefined;

  const select = (value: string) => () => {
    onChange && onChange(value);
    setShowPicker(false);
  };
  return (
    <>
      <InputView
        {...{
          label,
          borderColor,
          state,
          style,
          contentStyle,
          placeholder: t("Click to add payment method"),
        }}
        renderContent={(contentProps) => {
          const { style, placeholder } = contentProps;
          return (
            <TouchableOpacity
              style={[styles.content, style]}
              disabled={disabled}
              {...otherProps}
              onPress={() => setShowPicker(true)}
            >
              {option?.render() || <Text>{placeholder}</Text>}
            </TouchableOpacity>
          );
        }}
      />
      {showPicker && (
        <PopUp
          onRequestClose={() => setShowPicker(false)}
          visible={visible !== false && showPicker}
          {...otherPicker}
        >
          {options.map((option) => (
            <TouchableOpacity key={option.id} onPress={select(option.id)}>
              {option.render(option.id === (value || defaultValue))}
            </TouchableOpacity>
          ))}
          {footer}
        </PopUp>
      )}
    </>
  );
};

export default Select;
