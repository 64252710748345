import { RouteProp } from "@react-navigation/core";
import { StackNavigationProp } from "@react-navigation/stack";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import { Platform, StyleSheet, View } from "react-native";

import { Page, Loading, BookingDirectStatus } from "../../components";
import { reportError } from "../../helpers";
import { PublicStackParamList } from "../../routes/public";
import { Booking, useStore, withAnonymous } from "../../stores";
import { Margins, Radius } from "../../storybook";

const styles = StyleSheet.create({
  spot: {
    borderTopStartRadius: Radius.regular,
    borderTopEndRadius: Radius.regular,
    overflow: "hidden",
  },
  content: {
    paddingVertical: Margins.regular,
  },
});

type PayNavigationProp = StackNavigationProp<PublicStackParamList, "PublicPay">;
type PayRouteProp = RouteProp<PublicStackParamList, "PublicPay">;

interface Props {
  navigation: PayNavigationProp;
  route: PayRouteProp;
}
const Pay = (props: Props) => {
  const { route, navigation } = props;
  const { bookingId, action, checkout } = route.params || {};
  const store = useStore();
  const { payment } = store;
  const booking = useMemo(
    () => new Booking(`bookings/${bookingId}`, {}, store),
    [bookingId]
  );
  const spot = useMemo(() => booking.spot, [booking.isLoaded]);
  const [error, setError] = useState<string>();

  useEffect(() => {
    const startCheckout = async () => {
      try {
        if (action) return; // Don't checkout on success of cancel
        if (!bookingId) throw new Error("Booking id not found");

        await payment.checkout({ bookingId, checkout });
      } catch (error) {
        const { message } = error as Error;
        console.error("Error: ", error);
        reportError(error as Error, {
          name: `public/${checkout ? "checkout/" : ""}pay`,
          type: "web",
          args: {
            bookingId,
          },
        });
        setError(message);
      }
    };
    startCheckout();
  }, []);

  const retry = () => {
    if (Platform.OS === "web") {
      window.location.href = `/public/${
        checkout ? "checkout/" : ""
      }pay/${bookingId}`;
    } else {
      navigation.navigate(checkout ? "PublicPayCheckout" : "PublicPay", {
        bookingId,
      });
    }
  };

  if ((!action && !error) || !spot?.isLoaded) return <Loading />;

  return (
    <Page spot={spot}>
      <View style={styles.content}>
        <BookingDirectStatus
          status={error ? "error" : action}
          booking={booking}
          onRetry={retry}
          error={error}
          checkout={checkout}
        />
      </View>
    </Page>
  );
};

export default withAnonymous(observer(Pay));
