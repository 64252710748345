import { RouteProp } from "@react-navigation/core";
import { StackNavigationProp } from "@react-navigation/stack";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState, ReactNode } from "react";
import { useCookies } from "react-cookie";
import { Trans, useTranslation } from "react-i18next";
import { Platform, StyleSheet, View } from "react-native";

import { Page, SpotClosing, SpotRates, VehicleForm } from "../../components";
import SpotDetails from "../../components/general/spotDetails";
import SpotGracePeriod from "../../components/spot/gracePeriod";
import { reportError, translatedError } from "../../helpers";
import { PublicStackParamList } from "../../routes/public";
import { Bookings, Spot, useStore, withAuthentication } from "../../stores";
import SpotLastScanned from "../../stores/parking/spot/spotLastScanned";
import { sanitizeLicense } from "../../stores/parking/vehicle/vehicles";
import {
  Button,
  Margins,
  Radius,
  Title,
  Vehicle,
  ListItem,
} from "../../storybook";

const COOKIE_LICENSE_PLATE = "licensePlate";
const COOKIE_COUNTRY = "country";
const COOKIE_MAX_AGE = 365 * 24 * 60 * 60;

const styles = StyleSheet.create({
  spot: {
    borderTopStartRadius: Radius.regular,
    borderTopEndRadius: Radius.regular,
    overflow: "hidden",
  },
  content: {
    paddingVertical: Margins.regular,
  },
  title: {
    marginBottom: Margins.small,
  },
  item: {
    marginVertical: Margins.small,
  },
  row: {
    flexDirection: "row",
    gap: Margins.small,
  },
  buttons: {
    width: "100%",
  },
});

interface Data {
  vehicle?: Vehicle;
}

type StartNavigationProp = StackNavigationProp<
  PublicStackParamList,
  "PublicStart"
>;
type StartRouteProp = RouteProp<PublicStackParamList, "PublicStart">;

interface Props {
  navigation: StartNavigationProp;
  route: StartRouteProp;
}

const Start = (props: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ReactNode>();
  const store = useStore();
  const [cookies, setCookie] = useCookies([
    COOKIE_LICENSE_PLATE,
    COOKIE_COUNTRY,
  ]);
  const [value, setValue] = useState<Data>({
    vehicle: {
      code: cookies[COOKIE_LICENSE_PLATE] || "",
      country: cookies[COOKIE_COUNTRY] || "NL",
    } as Vehicle,
  });

  const { route, navigation } = props;
  const {
    spotId,
    utm_source,
    utm_campaign,
    utm_content,
    utm_medium,
    utm_term,
  } = route.params;

  const utmParams = {
    utm_source,
    utm_medium,
    utm_campaign,
    utm_term,
    utm_content,
  };
  const { auth, parking } = store;
  const { isSignedIn } = auth;
  const { userVehicles } = parking;
  const { vehicle } = value;

  const spot = useMemo(
    () => new Spot(`spots/${spotId}`, {}, store),
    [spotId, isSignedIn]
  );

  const spotLastScanned = useMemo(
    () => new SpotLastScanned(`spotLastScanned/${spotId}`),
    [spotId]
  );

  const { rates } = spot;

  const availability = useMemo(
    () =>
      spot.isLoaded
        ? spot.getAvailabilityStatus({
            startAt: new Date(),
            endAt: new Date(Date.now() + 24 * 60 * 60 * 1000),
          })
        : undefined,
    [spot.isLoaded]
  );

  const closingAvailability = useMemo(
    () =>
      spot.isLoaded
        ? spot.getAvailabilityStatus({
            startAt: new Date(),
            endAt: new Date(Date.now() + 24 * 60 * 60 * 1000),
            parkWhenClosed: false,
          })
        : undefined,
    [spot.isLoaded]
  );

  useEffect(() => {
    if (error) {
      setTimeout(() => setError(undefined), 3000);
    }
  }, [error]);

  const updateVehicle = (value: Vehicle) => setValue({ vehicle: value });

  const getVehicle = async () => {
    if (!vehicle) return;

    await userVehicles.fetch();
    const licenseRef = sanitizeLicense(vehicle.code || "");
    const found = userVehicles.docs.find(
      (doc) => doc.licensePlate === licenseRef
    );

    if (found) {
      console.info(`Found vehicle: ${found.id}`);
      return found;
    }

    const data = {
      licensePlate: vehicle.code || "",
      country: vehicle.country as string,
      licenseRef,
      brand: vehicle.vehicle,
    };

    const vehicleDoc = await userVehicles.create(data);
    console.info(`New vehicle: ${vehicleDoc.id}`);
    return vehicleDoc;
  };

  const create = async () => {
    setLoading(true);
    let args;

    try {
      if (!vehicle?.code || !vehicle.country) {
        throw new Error("Vehicle not found");
      }

      if (auth.user?.id) {
        const bookings = new Bookings(
          { userId: auth.user.id, payment: "failed" },
          store
        );
        const failed = await bookings.fetch();
        if (failed.docs.length > 0) {
          throw new Error("You have open payments");
        }
      }

      const licenseRef = sanitizeLicense(vehicle.code);
      const bookings = new Bookings(
        { spotId, "vehicle.licenseRef": licenseRef },
        store
      );
      await bookings.fetch();

      let newBooking = bookings.hasDocs ? bookings.docs[0] : undefined;

      if (!newBooking) {
        const userId = auth.user?.id;
        if (!userId) return;

        const vehicleDoc = await getVehicle();
        if (!vehicleDoc?.id) throw new Error("Failed to create vehicle");

        const endAt =
          availability && !availability.open ? availability.endAt : undefined;
        args = { endAt, spotId: spot.id, vehicleIds: [vehicleDoc.id] };

        const response = await bookings.request({
          anonymous: true,
          endAt,
          spot,
          vehicleIds: [vehicleDoc.id],
          utmParams,
        });

        newBooking = response.booking;
        console.info(`New booking: ${newBooking?.id}`);
      } else {
        args = { bookingId: newBooking.id, spotId: spot.id };
        console.info(`Active booking: ${newBooking.id}`);
      }

      await newBooking?.openGate("entrance");

      setCookie(COOKIE_LICENSE_PLATE, vehicle.code, { maxAge: COOKIE_MAX_AGE });
      setCookie(COOKIE_COUNTRY, vehicle.country, { maxAge: COOKIE_MAX_AGE });

      const bookingId = newBooking?.id || "";
      if (Platform.OS === "web") {
        window.location.href = `/public/started/${bookingId}`;
      } else {
        navigation.navigate("PublicStarted", { bookingId });
      }
    } catch (err) {
      const { message } = err as Error;
      console.error("Error: ", message);
      reportError(err as Error, {
        name: "public/start",
        type: "web",
        args,
      });
      setError(translatedError(message));
    }

    setLoading(false);
  };

  const licensePlateValid = (vehicle?.code?.length || 0) > 2;
  const isClosed =
    closingAvailability &&
    closingAvailability.startAt.getTime() ===
      closingAvailability.endAt.getTime();

  if (spot.isLoaded && !spot.name) {
    // @ts-ignore
    navigation.navigate("Home");
    return;
  }

  return (
    <Page spot={spot}>
      <View style={styles.content}>
        <Title level={2} style={styles.title}>
          <Trans>Start parking at</Trans> {spot.name}
        </Title>
        <SpotDetails spot={spot} />
        <Title style={{ marginBottom: Margins.tiny }}>
          <Trans>License plate</Trans>
        </Title>
        <View style={styles.row}>
          <VehicleForm value={vehicle} onChange={updateVehicle} />
          {!!spotLastScanned?.license &&
            spotLastScanned.license !== "" &&
            spotLastScanned.license !== vehicle?.code && (
              <Button
                title={t("Auto-Fill with") + " " + spotLastScanned.license}
                size="small"
                type="black"
                onPress={() => updateVehicle({ code: spotLastScanned.license })}
              />
            )}
        </View>
        {vehicle?.vehicle && (
          <ListItem
            style={styles.item}
            title={t("Found vehicle")}
            description={vehicle.vehicle}
          />
        )}
        <SpotRates rates={rates} style={styles.item} />
        <SpotGracePeriod gracePeriod={spot.gracePeriod} style={styles.item} />
        <SpotClosing availability={closingAvailability} spot={spot} />
        <Button
          title={error || t("Start parking")}
          type={error ? "red" : undefined}
          centre="logIn"
          style={styles.item}
          disabled={!licensePlateValid || loading || isClosed}
          onPress={create}
          loading={loading}
        />
      </View>
    </Page>
  );
};

export default withAuthentication(observer(Start));
