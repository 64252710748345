import { useEffect } from "react";
import { Platform } from "react-native";

export const useDeepLink = (navigation: any, route: any) => {
  // get data from url
  const params = route?.params;
  const spotId = params?.spotId;
  const voucherCode = params?.voucherCode;
  const shareId = params?.shareId;

  useEffect(() => {
    if (spotId) {
      if (Platform.OS === "web") {
        window.location.href = `/public/start/${spotId}`;
      } else {
        navigation.navigate("PublicStart", { spotId });
      }
    }
    if (voucherCode) {
      if (Platform.OS === "web") {
        window.location.href = `/voucher/${voucherCode}`;
      } else {
        navigation.navigate("Voucher", { voucherCode });
      }
    }
    if (shareId) {
      if (Platform.OS === "web") {
        window.location.href = `/share/${shareId}`;
      } else {
        navigation.navigate("BookingShare", { shareId });
      }
    }
  }, [spotId, voucherCode, shareId]);
};
