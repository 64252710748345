import React from "react";
import { useTranslation } from "react-i18next";
import { View, StyleSheet, ScrollView, Platform } from "react-native";

import { DetailsMiniCard } from "./DetailsMiniCard";
import { currencyToDisplayString } from "../../../helpers/currency";
import {
  PackageType,
  SpotSingleAccessPackage,
} from "../../../stores/parking/spot";
import { Margins, Title } from "../../../storybook";

type PropsType = {
  packages: SpotSingleAccessPackage[];
  selectedPackage?: SpotSingleAccessPackage | null;
  setSelectedPackage?: (selected: SpotSingleAccessPackage) => void;
};

const SpotDetailsPackagesSection = (props: PropsType) => {
  const { packages, selectedPackage, setSelectedPackage } = props;
  const { t } = useTranslation();

  const handleOnCardPress = (accessPackage: SpotSingleAccessPackage) => {
    if (setSelectedPackage) {
      setSelectedPackage(accessPackage);
    }
  };

  const middleText = (type: PackageType, duration: number) => {
    if (type === "morning") return t("Per morning");
    if (type === "afternoon") return t("Per afternoon");
    if (type === "day") return t("Per day");
    if (type === "night") return t("Per night");
    if (type === "weekend") return t("Per weekend");
    return t(`Per $1 hours`, `${duration}`);
  };

  const durationString = (duration: number, fixedStartHour: number) => {
    const endHour = (fixedStartHour + duration) % 24;
    return `${fixedStartHour.toString().padStart(2, "0")}:00 - ${endHour
      .toString()
      .padStart(2, "0")}:00`;
  };

  return (
    <View style={styles.container}>
      <Title>{t("Packages")}</Title>
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        style={styles.scrollView}
        contentContainerStyle={styles.inner}
      >
        {(packages || []).map((singleAccessPackage) =>
          singleAccessPackage ? (
            <DetailsMiniCard
              key={singleAccessPackage.type}
              isActive={selectedPackage === singleAccessPackage}
              onPress={() => handleOnCardPress(singleAccessPackage)}
              topText={`${currencyToDisplayString(
                singleAccessPackage?.rate?.currency,
                singleAccessPackage?.rate?.value
              )} `}
              middleText={middleText(
                singleAccessPackage?.type,
                singleAccessPackage?.duration
              )}
              bottomText={durationString(
                singleAccessPackage?.duration,
                singleAccessPackage?.fixedStartHour ?? 0
              )}
            />
          ) : null
        )}
      </ScrollView>
    </View>
  );
};

export default SpotDetailsPackagesSection;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    // @ts-ignore
    maxWidth: Platform.OS === "web" ? "90vw" : undefined,
    marginTop: Margins.small,
  },
  scrollView: {
    marginTop: Margins.small,
    marginBottom: Margins.regular,
  },
  inner: {
    gap: Margins.small,
  },
});
