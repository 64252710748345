import { RouteProp } from "@react-navigation/core";
import { StackNavigationProp } from "@react-navigation/stack";
import { observer } from "mobx-react";
import React, { useMemo } from "react";
import { Trans } from "react-i18next";
import { Platform, StyleSheet } from "react-native";

import { Page, BookingReservationShared } from "../../components";
import { BookingStackParamList } from "../../routes/booking";
import { Booking, useStore, withRegistration } from "../../stores";
import { Margins, Radius, Title } from "../../storybook";

type BookingNavigationProp = StackNavigationProp<
  BookingStackParamList,
  "BookingDetail"
>;
type BookingRouteProp = RouteProp<BookingStackParamList, "BookingDetail">;

interface Props {
  navigation: BookingNavigationProp;
  route: BookingRouteProp;
}

const SharedDetail = (props: Props) => {
  const { route, navigation } = props;
  const store = useStore();
  const { bookingId } = route.params || {};

  const booking = useMemo(
    () => new Booking(`bookings/${bookingId}`, {}, store),
    [bookingId]
  );

  const { spot, vehicle, startAt, endAt } = booking;

  if (!booking.isLoaded || !spot) return null;

  const retry = () => {
    const spotId = spot?.id || "";
    if (!vehicle || !startAt || !endAt) throw new Error("Data missing");
    if (Platform.OS === "web") {
      window.location.href = `/payment/${spot?.id}?vehicles[]=${
        vehicle.country || "NL"
      }:${
        vehicle.licensePlate
      }&country=&startAt=${startAt.toISOString()}&endAt=${endAt.toISOString()}`;
    } else {
      navigation.navigate("BookingPayment", {
        spotId,
        vehicles: JSON.stringify([
          { country: vehicle.country || "NL", code: vehicle.licensePlate },
        ]),
        startAt: startAt.toISOString(),
        endAt: endAt.toISOString(),
      });
    }
  };

  return (
    <Page spot={spot}>
      {!booking.hasData ? (
        <Title level={1} style={styles.notfound}>
          <Trans>Booking not found</Trans>
        </Title>
      ) : (
        <BookingReservationShared booking={booking} onRetry={retry} />
      )}
    </Page>
  );
};

export default withRegistration(observer(SharedDetail));

const styles = StyleSheet.create({
  spot: {
    borderTopStartRadius: Radius.regular,
    borderTopEndRadius: Radius.regular,
    overflow: "hidden",
  },
  notfound: { margin: Margins.large, textAlign: "center" },
  button: { margin: Margins.small },
});
