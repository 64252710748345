import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { View, ViewProps } from "react-native";

import { SpotGracePeriod as SpotGracePeriodType } from "../../stores";
import { ListItem, Text } from "../../storybook";

interface Props extends ViewProps {
  gracePeriod?: SpotGracePeriodType;
}

const SpotGracePeriod = (props: Props) => {
  const { gracePeriod, ...otherProps } = props;
  const { t } = useTranslation();

  if (!gracePeriod?.gracePeriodEnabled) {
    return null;
  }

  return (
    <View {...otherProps}>
      <ListItem
        title={t("Grace period")}
        description={
          <Text>
            <Trans>
              The first {{ minutes: gracePeriod.durationInMinutes }} minutes are
              free.
              {gracePeriod.residentsOnly &&
                ` This grace period is only available for residents.`}
            </Trans>
          </Text>
        }
      />
    </View>
  );
};

export default SpotGracePeriod;
