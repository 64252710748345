import { createNativeStackNavigator } from "@react-navigation/native-stack";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { UTMParams } from "./booking";
import {
  AuthSignIn,
  AuthEmail,
  AuthEmailSent,
  AuthEmailConfirm,
  AuthSMS,
  AuthSMSConfirm,
} from "../screens";

type CommonAuthParams = {
  next: string;
  spotId?: string;
  embed?: string;
  verify?: boolean;
} & UTMParams;

export type AuthStackParamList = {
  AuthSignIn: CommonAuthParams;
  AuthEmail: CommonAuthParams;
  AuthEmailSent: CommonAuthParams;
  AuthEmailConfirm: CommonAuthParams;
  AuthSMS: CommonAuthParams;
  AuthSMSSent: CommonAuthParams;
  AuthSMSConfirm: CommonAuthParams;
};

const Stack = createNativeStackNavigator<AuthStackParamList>();

const Auth = () => {
  const { t } = useTranslation();
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen
        name="AuthSignIn"
        component={AuthSignIn}
        options={{ title: `My Way | ${t("Sign in")}` }}
      />
      <Stack.Screen
        name="AuthEmail"
        component={AuthEmail}
        options={{ title: `My Way | ${t("Email sign in")}` }}
      />
      <Stack.Screen
        name="AuthEmailSent"
        component={AuthEmailSent}
        options={{ title: `My Way | ${t("Email sent")}` }}
      />
      <Stack.Screen
        name="AuthEmailConfirm"
        component={AuthEmailConfirm}
        options={{ title: `My Way | ${t("Verify email sign in")}` }}
      />

      <Stack.Screen
        name="AuthSMS"
        component={AuthSMS}
        options={{ title: `My Way | ${t("SMS sign in")}` }}
      />
      <Stack.Screen
        name="AuthSMSConfirm"
        component={AuthSMSConfirm}
        options={{ title: `My Way | ${t("Verify SMS code")}` }}
      />
    </Stack.Navigator>
  );
};

export default Auth;
