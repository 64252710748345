import { format as formatDate, getYear } from "date-fns";
import React from "react";
import { View, StyleSheet } from "react-native";

import TimeInput, { Time as Time_t } from "./timeinput";
import { Margins, Fonts } from "../../constant";
import { Text } from "../typography";

export type Time = Time_t | undefined;

const styles = StyleSheet.create({
  container: {
    padding: Margins.regular,
    flexDirection: "row",
    alignItems: "center",
  },
  content: {
    flexDirection: "row",
  },
  title: {
    fontFamily: Fonts.value,
    flex: 1,
  },
  input: {
    marginLeft: Margins.small,
  },
  date: {
    width: 60,
    marginLeft: Margins.small,
    textAlign: "center",
    marginBottom: Margins.tiny,
  },
});

interface PropsSingle {
  range?: false;
  value?: Time;
  date?: Date;
  onChange?: (value: Time) => void;
}

interface PropsRange {
  range?: true;
  value?: Time[];
  dates?: Date[];
  onChange?: (value: Time[]) => void;
  disabled?: boolean;
}

type Props = PropsSingle | PropsRange;

const dateToText = (date?: Date) => {
  if (!date) return "";
  const text = formatDate(date, "PP");
  const year = getYear(date);
  return text.replace(`, ${year}`, "");
};

const CalendarTime = (props: Props) => {
  const renderRange = () => {
    const { onChange, dates, disabled = false } = props as PropsRange;
    const value = (props as PropsRange).value || [];
    return (
      <View>
        <View style={styles.content}>
          <Text style={styles.date}>
            {dateToText(dates ? dates[0] : undefined)}
          </Text>
          <Text style={styles.date}>
            {dateToText(dates ? dates[1] : undefined)}
          </Text>
        </View>
        <View style={styles.content}>
          <TimeInput
            style={styles.input}
            key="from"
            value={value ? value[0] : undefined}
            disabled={disabled}
            onChange={(time) => onChange && onChange([time, value[1]])}
          />
          <TimeInput
            style={styles.input}
            key="to"
            value={value ? value[1] : undefined}
            disabled={disabled}
            onChange={(time) => onChange && onChange([value[0], time])}
          />
        </View>
      </View>
    );
  };

  const renderSingle = () => {
    const { value, onChange } = props as PropsSingle;
    const { date } = props as PropsSingle;
    return (
      <View>
        <Text style={styles.date}>{dateToText(date)}</Text>
        <TimeInput style={styles.input} value={value} onChange={onChange} />
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Time</Text>
      {props.range ? renderRange() : renderSingle()}
    </View>
  );
};

export default CalendarTime;
