import { RouteProp } from "@react-navigation/core";
import { StackNavigationProp } from "@react-navigation/stack";
import * as Clipboard from "expo-clipboard";
import { observer } from "mobx-react";
import React, { useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Linking, Platform, StyleSheet, View } from "react-native";

import { Page, BookingReservationStatus } from "../../components";
import { BookingStackParamList } from "../../routes/booking";
import { Booking, useStore, withRegistration } from "../../stores";
import { Action, Button, Margins, Radius, Title } from "../../storybook";

type BookingNavigationProp = StackNavigationProp<
  BookingStackParamList,
  "BookingDetail"
>;
type BookingRouteProp = RouteProp<BookingStackParamList, "BookingDetail">;

const styles = StyleSheet.create({
  notfound: {
    margin: Margins.large,
    textAlign: "center",
  },
  spot: {
    borderTopStartRadius: Radius.regular,
    borderTopEndRadius: Radius.regular,
    overflow: "hidden",
  },
  button: {
    margin: Margins.small,
    flex: 1,
  },
});

interface Props {
  navigation: BookingNavigationProp;
  route: BookingRouteProp;
}

const Detail = (props: Props) => {
  const { route, navigation } = props;
  const [shareLink, setShareLink] = useState<string | undefined>();
  const { t } = useTranslation();
  const store = useStore();
  const { bookingId } = route.params || {};

  const booking = useMemo(
    () => new Booking(`bookings/${bookingId}`, {}, store),
    [bookingId]
  );
  const { spot } = booking;
  if (!booking.isLoaded || !spot) return null;

  const retry = () => {
    const { vehicle, startAt, endAt, spot } = booking;
    const spotId = spot?.id || "";
    if (!vehicle || !startAt || !endAt) throw new Error("Data missing");
    if (Platform.OS === "web") {
      window.location.href = `/reservation/${spot?.id}?vehicles[]=${
        vehicle.country || "NL"
      }:${
        vehicle.licensePlate
      }&country=&startAt=${startAt.toISOString()}&endAt=${endAt.toISOString()}`;
    } else {
      navigation.navigate("BookingPayment", {
        spotId,
        vehicles: JSON.stringify([
          { country: vehicle.country || "NL", code: vehicle.licensePlate },
        ]),
        startAt: startAt.toISOString(),
        endAt: endAt.toISOString(),
      });
    }
  };

  const share = async () => {
    const { link } = await booking.share();
    setShareLink(link);
  };

  const shareClipboard = async () => {
    await Clipboard.setStringAsync(shareLink || "");

    shareClose();
  };

  const shareClose = () => {
    setShareLink(undefined);
  };

  const shareEmail = async () => {
    await Linking.openURL(
      `mailto:?to=&body=${shareLink}&subject=${t("My Way parking reservation")}`
    );
    shareClose();
  };

  return (
    <Page spot={spot}>
      {!booking.hasData ? (
        <Title level={1} style={styles.notfound}>
          <Trans>Booking not found</Trans>
        </Title>
      ) : (
        <>
          <BookingReservationStatus
            booking={booking}
            onRetry={retry}
            onShare={share}
          />
        </>
      )}
      <Action visible={!!shareLink} onRequestClose={shareClose}>
        <View
          style={{
            maxWidth: 600,
            width: "100%",
            marginHorizontal: "auto",
            paddingHorizontal: Margins.regular,
          }}
        >
          <Button
            title={t("Share with email")}
            onPress={shareEmail}
            style={styles.button}
          />
          <Button
            title={t("Copy link to clipboard")}
            onPress={shareClipboard}
            style={styles.button}
          />
          <Button
            title="Cancel"
            onPress={shareClose}
            style={styles.button}
            type="white"
          />
        </View>
      </Action>
    </Page>
  );
};

export default withRegistration(observer(Detail));
