import firebase from "firebase/compat/app";
import moment from "moment-timezone";

import { Options, getAvailabilityStatus, Status } from "./helpers";
import { currencyToDisplayString } from "../../../helpers";
import Document from "../../general/document";
import { Bookings } from "../booking";
import { GateData } from "../gate";

export type AvailabilityStatus = Status;

export interface WeekDayAvailability {
  closed?: boolean;
  startAt?: string; // E.g. "7:30"
  endAt?: string; // E.g. "18:00"
}

export interface WeekDaysAvailability {
  [key: string]: WeekDayAvailability;
}

export interface SpotAvailability {
  startAt: firebase.firestore.Timestamp;
  endAt: firebase.firestore.Timestamp;
  open: boolean;
  //vehicleCount: number,
}

interface PhotoData {
  url: string;
  sortOrder: number;
}

export interface SpotRate {
  value: number;
  currency: string; // ISO 4217, 3 uppercase character code (e.g. "EUR", "USD")
  min?: number;
  daily?: number;
}

export interface SpotRates {
  hourly: SpotRate;
}

export interface SpotAddress {
  street: string;
  additional?: string;
  postalCode: string;
  city: string;
  countryCode: string; // ISO 3166-1 alpha-2, 2 uppercase country code (e.g. "NL", "US")
}

export interface SpotData {
  name?: string;
  openingTimes?: WeekDaysAvailability;
  availability?: {
    [availabilityId: string]: SpotAvailability;
  };
  location?: {
    latitude: number;
    longitude: number;
  };
  parkWhenClosed?: boolean;
  photos?: { [id: string]: PhotoData };
  rating?: number;
  count?: number;
  rates?: SpotRates;
  singleAccessPackages?: SpotSingleAccessPackage[];
  address: SpotAddress;
  external?: "aeroparking" | "schmidt";
  valet: boolean;
  private: boolean;
  entrance?: GateData;
  exit?: GateData;
  access?: GateData;
  gracePeriod?: SpotGracePeriod;
}

export type SpotGracePeriod = {
  discountInPercentage: number;
  durationInMinutes: number;
  gracePeriodEnabled: boolean;
  residentsOnly: boolean;
};

export type SpotSingleAccessPackage = {
  type: PackageType;
  duration: number;
  rate: SpotPackageRate;
  private?: boolean;
  fixedStartHour?: number;
};

export type SpotPackageRate = {
  period?: "monthly";
  value: number;
  currency: string; // ISO 4217, 3 uppercase character code (e.g. "EUR", "USD")
};
export type PackageType =
  | "morning"
  | "afternoon"
  | "day"
  | "night"
  | "weekend"
  | "commuter"
  | "fiveNightsAndWeekend"
  | "fiveDays"
  | "sixDays"
  | "always";

class Spot extends Document<SpotData> {
  private _bookings?: Bookings;

  get name() {
    return this.data.name;
  }

  get singleAccessPackages() {
    return this.data.singleAccessPackages;
  }

  get gracePeriod() {
    return this.data?.gracePeriod || undefined;
  }

  singleAccessPackageByType(type: PackageType) {
    return (this.data.singleAccessPackages || [])?.find(
      (i) => i?.type === type
    );
  }

  get photos() {
    return Object.values(this.data.photos || {})
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .map((photo) => photo.url);
  }
  get primaryPhoto() {
    return this.photos.length ? this.photos[0] : undefined;
  }

  get rating() {
    return this.data.rating || 0.0;
  }

  get count() {
    return this.data.count || 0;
  }

  get rates() {
    const { rates } = this.data;
    if (rates && rates.hourly && rates.hourly.currency) {
      return rates;
    }
    const hourly = {
      value: 0,
      currency: "EUR",
      ...(rates && rates.hourly ? rates.hourly : {}),
    };
    return { hourly };
  }

  get hourlyRate() {
    const { hourly } = this.rates;
    return hourly;
  }

  get displayRate(): string {
    const { hourly } = this.rates;
    return currencyToDisplayString(hourly.currency, hourly.value, true);
  }

  get location() {
    return this.data.location;
  }

  getAvailabilityStatus = (options: Options) => {
    const { startAt, endAt, parkWhenClosed } = options || {};
    const status = getAvailabilityStatus(this.data, {
      startAt,
      endAt,
      parkWhenClosed,
    });
    return status;
  };

  get address(): SpotAddress {
    return this.data.address;
  }

  get country(): string {
    const { address } = this;
    if (!address) return "NL";
    return address.countryCode || "NL";
  }

  get city(): string {
    const { address } = this;
    if (!address) return "";
    return address.city || "";
  }

  get analyticsData() {
    return {
      item_id: this.id,
      item_name: this.name,
      affiliation: "vicky-web",
      price: this.rates.hourly.value,
      item_brand: this.data.external || "gateguard",
      item_category: this.country,
      item_category2: this.city,
      item_category3: this.data.valet
        ? "valet"
        : this.data.private
        ? "private"
        : "public",
    };
  }

  get openingToday() {
    const day = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"][
      moment().isoWeekday() - 1
    ];
    const openingTimes = this.data.openingTimes && this.data.openingTimes[day];
    const time = openingTimes?.closed
      ? openingTimes?.endAt
      : openingTimes?.startAt;

    return time || "0.00";
  }

  get closingToday() {
    const day = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"][
      moment().isoWeekday() - 1
    ];
    const openingTimes = this.data.openingTimes && this.data.openingTimes[day];
    const time = openingTimes?.closed
      ? openingTimes?.startAt
      : openingTimes?.endAt;
    return time || "0.00";
  }

  get hasEntranceGate() {
    return this.hasGate(this.data.entrance);
  }

  get hasExitGate() {
    return this.hasGate(this.data.exit);
  }

  get hasAccessGate() {
    return this.hasGate(this.data.access);
  }

  get entranceInstructions() {
    return this.data.entrance?.steps;
  }

  get exitInstructions() {
    return this.data.exit?.steps;
  }

  private hasGate(gate?: GateData) {
    if (!gate) return false;
    const { gates } = gate;
    return !!gates && gates.length > 0;
  }
}

export default Spot;
