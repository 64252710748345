import { SpotGracePeriod, SpotRates } from "../..";
import { calculateDuration } from "../../../../helpers";

export interface BookingCost {
  amount: number;
  open?: number;
  currency: string;
}

export function getBookingCost(
  rates?: SpotRates,
  startAt?: Date,
  endAt?: Date,
  gracePeriod?: SpotGracePeriod,
  isOverdue: boolean = false,
  isWhitelist: boolean = false,
  unPaid: boolean = false
): BookingCost | null {
  const duration = calculateDuration(startAt, endAt);

  if (rates?.hourly) {
    const { value, min, daily } = rates.hourly;
    const hours = duration / (1000 * 60 * 60);

    // Apply grace period if configured
    if (
      gracePeriod?.durationInMinutes &&
      hours * 60 <= gracePeriod.durationInMinutes
    ) {
      return {
        amount: 0,
        currency: rates.hourly.currency,
      };
    }

    let price: number | undefined = undefined;
    if (isOverdue) {
      price = Math.round(100 * 1.2 * hours * value) / 100;
    } else if (daily) {
      const days = Math.floor(hours / 24);
      const remaining = hours - days * 24;
      price = Math.min(remaining * value, daily) + days * daily;
    } else {
      price = hours * value;
    }
    let minimum: number = 0;

    if (isWhitelist === false) {
      minimum = min !== undefined ? min : 0.5;
    } else if (price > 0 && price < 0.5) {
      minimum = 0.5;
    }
    if (unPaid) {
      return {
        amount: 0,
        open: Math.max(price, minimum), // minimum amount that can be charged by Stripe is 0.50
        currency: rates.hourly.currency,
      };
    }
    return {
      amount: Math.max(price, minimum), // minimum amount that can be charged by Stripe is 0.50
      currency: rates.hourly.currency,
    };
  }
  return null;
}
