import { observer } from "mobx-react";
import React, { FunctionComponent, useMemo } from "react";
import { useAsync } from "react-async-hook";
import { useTranslation } from "react-i18next";
import {
  StyleSheet,
  ViewProps,
  View,
  ImageBackground,
  ScrollView,
  KeyboardAvoidingView,
} from "react-native";
import { useMediaQuery } from "react-responsive";

import AppsflyerScript from "./appsflyer/script";
import Back from "./back";
import SEO from "./seo";
import Header from "../../../src/storybook/component/container/header";
import { getImagePath } from "../../helpers";
import { useStore } from "../../stores";
import { Spot } from "../../stores/parking";
import { Colors, Margins, MyWayLogo, Radius } from "../../storybook";
import { SpotCard } from "../spot";

interface Language {
  title: string;
  flag: string;
  locale: string;
}

interface Props extends ViewProps {
  embed?: boolean;
  footer?: boolean;
  spot?: Spot;
  tabletSizeScreen?: boolean;
}

const getPhotoUrl = (src?: string): Promise<string> => {
  return Promise.resolve(src ? getImagePath(src) : "");
};

const Page: FunctionComponent<Props> = (props) => {
  const { spot, embed, children, tabletSizeScreen } = props;
  const { i18n, history } = useStore();
  const { t } = useTranslation();
  const { canGoBack, back } = history;

  const isLargeDesktop = tabletSizeScreen
    ? false
    : useMediaQuery({ minWidth: 1200 });

  const photoUrl = useAsync(getPhotoUrl, [spot?.primaryPhoto]);

  const fallbackImage = useMemo(() => {
    // get random number between 1 and 3
    const random = Math.floor(Math.random() * 3) + 1;

    // return the image path
    const image = require(`../../../assets/images/background-${random}.png`);

    if (!image) {
      return undefined;
    }

    return image;
  }, []);

  if (embed) return <View style={styles.embed}>{children}</View>;

  const dataForLanguage = (language: string) => {
    switch (language) {
      case "nl":
        return { title: t("Dutch"), flag: "NL", locale: "nl-NL" };
      case "fr":
        return { title: t("French"), flag: "FR", locale: "fr" };
      case "de":
        return { title: t("German"), flag: "DE", locale: "de" };
      default:
        return { title: t("English"), flag: "GB", locale: "en-GB" };
    }
  };

  const languages = i18n.languages.reduce<{ [language: string]: Language }>(
    (acc, language) => ({
      ...acc,
      [language]: dataForLanguage(language),
    }),
    {}
  );

  const updateLanguage = (language: string) => {
    const { locale } = languages[language];
    i18n.changeLocale(locale);
  };

  return (
    <>
      <AppsflyerScript />
      <SEO
        title="My Way - The new smart way to park."
        description={t(
          "Get about in the city on your terms. Use dormant parking locations that are off the radar. Cheaper and safer than the street. Always ahead with a reservation. Parking your way."
        )}
        name="My Way"
      />
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        behavior="padding"
        enabled
        keyboardVerticalOffset={100}
      >
        <ScrollView
          style={styles.background}
          // @ts-ignore
          contentContainerStyle={{ minHeight: "100vh" }}
        >
          <View style={{ flex: 1 }}>
            {canGoBack && <Back style={styles.back} onPress={back} />}
            {!isLargeDesktop && languages && i18n.language && (
              <Header
                language={i18n.language}
                availableLanguages={languages}
                onChangeLanguage={updateLanguage}
              />
            )}
            {spot && <SpotCard spot={spot} />}
            {isLargeDesktop ? (
              <View style={{ flexDirection: "row", flex: 1 }}>
                <View style={styles.contentLarge}>
                  {languages && i18n.language && (
                    <Header
                      language={i18n.language}
                      availableLanguages={languages}
                      onChangeLanguage={updateLanguage}
                    />
                  )}
                  <View
                    style={{ flex: 1, maxWidth: 600, justifyContent: "center" }}
                  >
                    {children}
                  </View>
                </View>
                <View
                  style={{
                    flex: 3,
                    // @ts-ignore
                    clipPath: "polygon(0 0, 100% 0, 100% 100%, 30% 100%)",
                    backgroundColor: Colors.moonMist,
                  }}
                >
                  <ImageBackground
                    resizeMode="cover"
                    source={{
                      uri: photoUrl.result ? photoUrl.result : fallbackImage,
                    }}
                    style={styles.imageBackground}
                  />
                  <View
                    // @ts-ignore
                    style={{
                      zIndex: 1,
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: [
                        { translateX: "-50%" },
                        { translateY: "-50%" },
                      ],
                    }}
                  >
                    <MyWayLogo color={Colors.lime} width={250} height={250} />
                  </View>
                </View>
              </View>
            ) : (
              <View style={styles.content}>{children}</View>
            )}
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
    </>
  );
};

const styles = StyleSheet.create({
  embed: {
    backgroundColor: Colors.white,
  },
  background: {
    backgroundColor: Colors.cream,
  },
  imageBackground: {
    flex: 1,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  buttons: {
    flexDirection: "row",
    paddingVertical: Margins.tiny,
    paddingHorizontal: Margins.small,
  },
  content: {
    paddingTop: Margins.regular,
    paddingHorizontal: Margins.regular,
    paddingBottom: Margins.extraLarge,
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: 600,
    width: "100%",
    marginHorizontal: "auto",
  },
  contentLarge: {
    flex: 2,
    paddingHorizontal: Margins.regular,
    // height of navbar
    paddingBottom: 80,
    alignItems: "center",
    width: "100%",
    marginHorizontal: "auto",
  },
  back: {
    position: "absolute",
    left: -(40 + Margins.small),
    top: 0,
    width: 40,
    height: 40,
  },
  overlay: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: "center",
    alignItems: "center",
  },
  customShape: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  link: {
    margin: 10,
  },
  image: {
    height: 40,
    width: 120,
    borderRadius: Radius.small,
    backgroundColor: Colors.black,
  },
  footer: {
    alignItems: "center",
  },
});

export default observer(Page);
