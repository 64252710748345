import React from "react";
import { TouchableHighlight, StyleSheet } from "react-native";

import { Colors, Margins, Text } from "../../../storybook";

type DetailsMiniCardProps = {
  topText?: string;
  middleText?: string;
  bottomText?: string;
  isActive: boolean;
  onPress?: () => void;
  outline?: boolean;
};

export const DetailsMiniCard = ({
  topText,
  middleText,
  bottomText,
  isActive,
  onPress,
  outline = false,
}: DetailsMiniCardProps) => {
  const backgroundColor = outline
    ? "transparent"
    : isActive
    ? Colors.forest
    : Colors.white;

  const textColor = outline
    ? isActive
      ? Colors.forest
      : Colors.black
    : isActive
    ? Colors.white
    : Colors.forest;

  const borderStyles = outline
    ? { borderWidth: 1, borderColor: isActive ? Colors.lime : Colors.forest }
    : {};

  return (
    <TouchableHighlight
      style={[styles.container, { backgroundColor }, borderStyles]}
      onPress={onPress}
      underlayColor="rgba(0, 0, 0, 0.1)"
      disabled={!onPress}
    >
      <>
        {topText && <Text style={{ color: textColor }}>{topText}</Text>}
        {middleText && <Text style={{ color: textColor }}>{middleText}</Text>}
        {bottomText && <Text style={{ color: textColor }}>{bottomText}</Text>}
      </>
    </TouchableHighlight>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 10,
    padding: Margins.small,
    gap: 5,
  },
});
